<template>
  <div id="signup-page">
    <div class="signup-content d-flex">
      <b-row
        class="w-100 justify-content-center"
        align-v="center"
        no-gutters>
        <b-col
          class="left-col"
          cols="12"
          md="12"
          lg="5"
          xl="5">
          <img
            class="pj-signup img-fluid"
            src="../../assets/images/PJ-Signup.png"
            alt="PJ Panda"
          />
        </b-col>
        <b-col
          cols="12"
          md="12"
          lg="9"
          xl="7">
          <AdultForm
            class="adult-form"
            v-if="$route.path === '/sign-up'"
            :birthdate="birthdate"
            :age="age"
          />
          <FreeForm
            class="free-form"
            v-if="$route.path === '/free-sign-up'"
            :birthdate="birthdate"
            :age="age"
          />
        </b-col>
      </b-row>
    </div>

    <!-- Modal -->
    <modals-container />
  </div>
</template>

<script>
  import BreakpointMixin from '@/mixins/BreakpointMixin';
  import BirthdateModal from '@/components/common/BirthdayModal';

  export default {
    head : {
      title : {
        inner      : 'Sign Up',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
    },
    mixins : [
      BreakpointMixin,
    ],
    data() {
      return {
        birthdate : null,
        age       : null,
      }
    },
    components : {
      'AdultForm' : () => import('./AdultForm'),
      'FreeForm'  : () => import('./FreeForm'),
    },
    methods : {

      /**
       * Show Birthdate Modal
       */
      showBirthdateModal() {
        this.$modal.show(BirthdateModal, {}, {
          height       : 'auto',
          width        : '100%',
          adaptive     : true,
          clickToClose : false,
          classes      : ['dob-modal'],
        }, {
          'before-close' : (event) => {
            if (localStorage.getItem('birthdate')) {
              this.birthdate = localStorage.getItem('birthdate');
              this.age = parseInt(localStorage.getItem('age'));

              if (this.$route.path === '/sign-up') {
                if (localStorage.getItem('age') < 18)
                  this.$router.push('/free-sign-up');
              }

              if (this.$route.path === '/free-sign-up') {
                if (localStorage.getItem('age') >= 18)
                  this.$router.push('/sign-up');
              }
            } else {
              this.loading = true;
              if (window.history.length > 2)
                this.$router.go(-1);
              else
                this.$router.push('/');
            }
          },
        });
      },
    },
    mounted() {
      if (!localStorage.getItem('birthdate'))
        this.showBirthdateModal();
    },
  }
</script>


<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  #signup-page {
    min-height: 100vh;
    background-image: $login-bg;
    background-attachment: fixed;
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .signup-content {
    min-height: 100vh;
  }

  .adult-form {
    margin-left: -18rem;
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    .signup-content {
      padding-top: 7rem;
      padding-bottom: 3rem;
    }
    .left-col {
      display: none;
    }
    .adult-form {
      margin-left: 0;
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .signup-content {
      padding-top: 8.5rem;
      padding-bottom: 3rem;
    }
    .left-col {
      display: none;
    }
    .adult-form {
      margin-left: 0;
    }
  }
  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .left-col {
      display: none;
    }
    .adult-form {
      margin-left: 0;
    }
  }
  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    .left-col {
      display: none;
    }
    .adult-form {
      margin-left: 0;
    }
  }
  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .signup-content {
      padding-top: 0;
      padding-bottom: 0;
      align-items: flex-end !important;
    }
    .left-col {
      display: block;
    }
    .adult-form {
      margin-left: -8rem;
    }
    .pj-signup {
      width: 100%;
    }
  }
  @media only screen and (min-width: $special) {
    .signup-content {
      padding-top: 7rem;
      padding-bottom: 0;
      align-items: flex-end !important;
    }

    .pj-signup {
      width: 100%;
    }
    .adult-form {
      margin-left: -8rem;
    }
  }
  @media only screen and (min-width: $xl2-min) {
    .signup-content {
      padding-top: 0;
      padding-bottom: 0;
      align-items: flex-end !important;
    }
    .left-col {
      display: block;
    }
    .pj-signup {
      width: 100%;
    }
    .adult-form {
      margin-left: -10rem;
    }
  }
  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    .signup-content {
      padding-top: 0;
      padding-bottom: 0;
      align-items: flex-end !important;
    }
    .pj-signup {
      width: 100%;
    }
    .adult-form {
      margin-left: -13rem;
    }
  }
</style>